import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import React, { useState, useCallback } from "react";

function AddressMap({ coordinates, mapSize }) {
  const containerStyle = {
    width: mapSize,
    height: "50vh",
  };
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey ,
    libraries: ["places", "maps"],
  });
  const [map, setMap] = useState(null);
  const [isLocationLoading, setIsLocationLoading] = useState(false);

  const onLoad = useCallback(
    function callback(map) {
      if (coordinates.lat && coordinates.lng) {
        const bounds = new window.google.maps.LatLngBounds();
        const padding = 0.001;
  
        bounds.extend(
          new window.google.maps.LatLng(coordinates.lat + padding, coordinates.lng + padding)
        );
        bounds.extend(
          new window.google.maps.LatLng(coordinates.lat - padding, coordinates.lng - padding)
        );
  
        // Use map only if it's available
        if (map) {
          map.fitBounds(bounds);
        } else {
          console.error('Map object is not yet available.');
        }
      } else {
        console.error("Invalid coordinates", coordinates);
      }
      setMap(map);
    },
    [coordinates]
  );
  
  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (loadError) return <div>Error loading Google Maps: {loadError.message}</div>;


  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={coordinates}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        disableDefaultUI: true,
      }}
    >
      <MarkerF
        position={coordinates}
        key={`${coordinates.lat},${coordinates.lng}`}
      />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(AddressMap);
