import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import NewAddress from "./myProfile/newAddressPage";
import MyCourses from "./myProfile/mycourses";
import LoginPage from "./loginPage";
import { Button, Flex, Typography } from "antd";

export default function DynamicNavigate() {
  const [currentStep, setCurrentStep] = useState("address");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const jwt = JSON.parse(localStorage.getItem("homeshiksha-tutor-jwt"));
    localStorage.setItem("dynamic", true);

    if (!jwt) {
      // User is not logged in, redirect to login
      setIsLoggedIn(false);
      navigate("/");
    } else {
      setIsLoggedIn(true);
    }

    if (localStorage.getItem("added_course")) {
      navigate("/dashboard");
      localStorage.removeItem("dynamic"); // Clear localStorage after course is added
      window.location.reload();
    }
  }, [navigate]);

  // Function to handle the next step
  const handleNextStep = () => {
    let newError = "";

    if (currentStep === "address" && !localStorage.getItem("address_updated")) {
      newError = "Please complete the address step first. If already updated click on proceed";
      setError({address: newError})
    } else if (
      currentStep === "course" &&
      !localStorage.getItem("added_course")
    ) {
      newError = "Please add at least one course. if already added click on proceed";
      setError({course: newError})
    }


    if (!newError) {
      // Proceed with navigation if no errors
      if (
        currentStep === "address" &&
        localStorage.getItem("address_updated")
      ) {
        setCurrentStep("course"); 
      } else if (
        currentStep === "course" &&
        localStorage.getItem("added_course")
      ) {
        setError({});
        navigate("/dashboard");
        localStorage.removeItem("dynamic"); // Clear localStorage items
        window.location.reload(); // Reload the page after navigation
      }
    }
  };

  const handleUpdatedAddress = () => {
    localStorage.setItem("address_updated", true);
    setCurrentStep("course");
  };

  const handleUpdatedCourse = () => {
    localStorage.setItem("added_course", true);
    navigate("/dashboard");
    localStorage.removeItem("dynamic"); // Clear localStorage items
    localStorage.removeItem("added_course");
    localStorage.removeItem("address_updated");
    window.location.reload();
  };

  return (
    <div>
      {isLoggedIn ? (
        <>
          {currentStep === "address" && (
            <Flex vertical gap="small" style={{ padding: "1rem" }}>
              <Typography.Title level={3} align="center">
                Update Address
              </Typography.Title>
              <NewAddress />
              {error && error?.address && <Typography.Text strong type="danger">{error?.address}</Typography.Text>}
              <Flex justify="flex-end" gap="small">
                <Button
                  type="primary"
                  style={{ background: "#274178" }}
                  onClick={handleNextStep}
                >
                  Add Courses
                </Button>
                <Button
                  type="primary"
                  style={{ background: "#274178" }}
                  onClick={handleUpdatedAddress}
                >
                  Proceed
                </Button>
              </Flex>
            </Flex>
          )}
          {currentStep === "course" && (
            <Flex vertical gap="small" style={{ padding: "1rem" }}>
              <Typography.Title level={3} align="center">
                Add Courses
              </Typography.Title>
              <MyCourses />
              {error && error?.course && <Typography.Text type="danger" strong>{error?.course}</Typography.Text>}
              <Flex justify="center" align="center" gap="small">
                <Button
                  type="primary"
                  style={{ background: "#274178" }}
                  onClick={handleNextStep}
                >
                  Go To Dashboard
                </Button>
                <Button
                  type="primary"
                  style={{ background: "#274178" }}
                  onClick={handleUpdatedCourse}
                >
                  Proceed
                </Button>
              </Flex>
            </Flex>
          )}
        </>
      ) : (
        <LoginPage />
      )}
    </div>
  );
}
