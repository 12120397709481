import { useAtom } from "jotai";
import { personalData } from "../../states/personalData";
import React, { useEffect, useState } from "react";
import { logEvent } from "../../analytics";
import { EditOutlined } from "@ant-design/icons";
import { EVENTS } from "../../constants/ga-constants";
import { Button, Card, Divider, notification, Space, Spin } from "antd";
import AddressMap from "./showMap";
import UpdateAddress from "./updateAddress";
import { dashboardCardsData } from "../../states/dashboardCardsData";
import axios from "../../axios";

const EditComponent = ({ handleClick }) => {
  return (
    <Button
      type="primary"
      icon={<EditOutlined />}
      onClick={handleClick}
      iconPosition="start"
      style={{background:"#274178"}}
    >
      Edit
    </Button>
  );
};

export default function NewAddress() {
  const [address, setAddress] = useAtom(personalData);
  const [editRequest, setEditRequest] = useState(false); // Start in view mode

  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [cardsCenteralData, setCardsCenteralData] = useAtom(dashboardCardsData);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, message, desc) => {
    api[type]({
      message: message,
      description: desc,
      placement: 'topRight', // Optional: Customize the placement
    });
  };

  const getPersonalDetails = () => {
    axios
      .get("/v1/user")
      .then((response) => {
        if (response.data.is_success) {
          const data = { ...response.data.data };
          let updatedData = {
            id: data.id,
            name: data.first_name,
            l_name: data.last_name,
            gender: data.gender,
            email: data.email,
            phone: data.phone,
            phone2: data.phone2,
            latitude: data.latitude,
            longitude: data.longitude,
            flat: data.flat === "undefined" ? "" : data.flat,
            apartment: data.apartment === "undefined" ? "" : data.apartment,
            street: data.street === "undefined" ? "" : data.street,
            locality: data.locality === "undefined" ? "" : data.locality,
            pincode: data.zip,
            city: data.city_name,
            state: data.state_name,
            tutor_verified: data.is_active,
            profile_pic_link: data.profile_pic_link
              ? data.profile_pic_link
              : "./person-icon.png",
          };
          setLoading(false);
          setAddress((pre) => ({ ...pre, ...updatedData }));
          setCoordinates({
            lat: updatedData.latitude,
            lng: updatedData.longitude,
          });
          logEvent(EVENTS.MY_ADDRESS.event_name);
        } else {
          throw response.data.error;
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (Object.keys(address).length === 0) {
      getPersonalDetails();
    } else {
      if(!address.latitude || !address.longitude){
        // setEditRequest(true)
      }
      else{
        setCoordinates({
          lat: address.latitude,
          lng: address.longitude,
        });
      }
      setLoading(false);
    }
  }, []);

  // // Automatically switch to edit mode if address is empty
  // useEffect(() => {
  //   if (!address || Object.keys(address).length === 0) {
  //     setEditRequest(true); // Switch to edit mode if no address
  //   }
  // }, [address]);

  const updateUserAddress = (_newData) => {
    // console.log(_newData);
    axios
      .post(`v1/updateUserDetails`, {
        id: _newData.id,
        first_name: _newData.name,
        last_name: _newData.l_name,
        phone: _newData.phone,
        phone2: _newData.phone2,
        email: _newData.email,
        flat: _newData.flat,
        apartment: _newData.apartment,
        street: _newData.street,
        city_name: _newData.city,
        state_name: _newData.state,
        locality: _newData.locality,
        gmap_meta_data: _newData.gmap_meta_data,
        zip: _newData.pincode,
        latitude: _newData.latitude,
        longitude: _newData.longitude,
        user_type: "tutor",
      })
      .then((data) => {
        
        getPersonalDetails();
        logEvent(EVENTS.UPDATED_ADDRESS.event_name);
        if(data && data?.status === 200){
          if(localStorage.getItem("dynamic")){
            localStorage.setItem("address_updated", true);
          }
          openNotificationWithIcon('success', "Address Update", "Address Updated Successfully")
        }
        return axios.get("/v1/tutor/dashboard-cards");
      })
      .then(async (response) => {
        setCardsCenteralData(response.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <>
      {address && !loading ? (
        !editRequest ? (
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Card
              title="Address"
              size="default"
              extra={<EditComponent handleClick={() => setEditRequest(true)} />}
            >
              <p>
                {address.flat +
                  " " +
                  address.apartment +
                  " " +
                  address.street +
                  " " +
                  address.city +
                  " " +
                  address.state +
                  " " +
                  address.pincode}
              </p>
              <Divider />
              {(address && address?.latitude && address?.longitude) && <AddressMap mapSize={"100%"} coordinates={coordinates} />}
            </Card>
          </Space>
        ) : (
          <UpdateAddress
            address={address}
            onEditClick={() => setEditRequest(false)}
            setCoordinates={setCoordinates}
            coordinates={coordinates}
            setAddress={setAddress}
            updateUserAddress={updateUserAddress}
          />
        )
      ) : (
        <Spin size="large" />
      )}
    </>
  );
}
