import {
  Button,
  Card,
  Divider,
  Flex,
  Form,
  Input,
  Space,
  Select,
  Typography,
  Row,
  Col,
  Spin,
  Modal,
} from "antd";
import AddressMap from "./showMap";
import { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { citysList } from "../../constants/form-templates";
import { logEvent } from "../../analytics";
import { EVENTS } from "../../constants/ga-constants";

export default function UpdateAddress({
  address,
  coordinates,
  setCoordinates,
  onEditClick,
  setAddress,
  updateUserAddress,
}) {
  const [searchAddress, setSearchAddress] = useState("");
  const [newAddress, setNewAddress] = useState(address || {});
  const [isPermissionBlocked, setIsPermissionBlocked] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    city: false,
    pincode: false,
  });
  const [addressText, setAddressText] = useState(
    address
      ? address.flat +
          " " +
          address.apartment +
          " " +
          address.street +
          " " +
          address.city +
          " " +
          address.state +
          " " +
          address.pincode
      : ""
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target || {};
    setNewAddress({
      ...newAddress,
      [name]: value,
    });
  };

  const findAddress = (addressComponents, place) => {
    let newAddress = {
      pincode: addressComponents.find((component) =>
        component.types.includes("postal_code")
      )?.long_name,
      city: addressComponents.find(
        (component) =>
          component.types.includes("locality") ||
          component.types.includes("sublocality") ||
          component.types.includes("neighborhood") ||
          component.types.includes("administrative_area_level_2")
      )?.long_name,
      state: addressComponents.find(
        (component) =>
          component.types.includes("administrative_area_level_1") &&
          component.types.includes("political")
      )?.long_name,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      flat: addressComponents.find((component) =>
        component.types.includes("street_number")
      )?.long_name,
      apartment: addressComponents.find((component) =>
        component.types.includes("subpremise")
      )?.long_name,
      street: addressComponents.find((component) =>
        component.types.includes("route")
      )?.long_name,
    };
    return newAddress;
  };

  const handlePlaceSelect = (place) => {
    let addressComponents = place.address_components;
    setSearchAddress(place.formatted_address);
    setAddressText(place.formatted_address);
    const newAddress = findAddress(addressComponents, place);
    setNewAddress((pre) => ({
      ...pre,
      ...newAddress,
      city: citysList.includes(newAddress.city) ? newAddress.city : "",
    }));
    setCoordinates({ lat: newAddress.latitude, lng: newAddress.longitude });
  };

  const fetchCurrentLocation = async () => {
    if (navigator.geolocation) {
      setLoading(true); // Start loading

      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        const response = await fetchAddressFromLatLng(lat, lng);
        setCoordinates({ lat, lng }); // Set coordinates
        setLoading(false); // Stop loading
        setNewAddress((prev) => ({
          ...prev,
          latitude: lat,
          longitude: lng,
          pincode: response?.pincode
        }));
        setSearchAddress(response?.formattedAddress);
      } catch (error) {
        setLoading(false); // Stop loading on error
        console.error("Error retrieving location:", error);
      }
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const fetchAddressFromLatLng = async (lat, lng) => {
    const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const geocodeURL = `${process.env.REACT_APP_GOOGLE_MAPS_API_URL}?latlng=${lat},${lng}&key=${API_KEY}`;
  
    try {
      const response = await fetch(geocodeURL);
      const data = await response.json();
  
      if (data.status === "OK") {
        const addressComponents = data.results[0]?.address_components || [];
        
        let formattedAddress = data.results[0]?.formatted_address;
        let city = addressComponents.find((component) =>
          component.types.includes("locality")
        )?.long_name;
        let state = addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name;
        let pincode = addressComponents.find((component) =>
          component.types.includes("postal_code")
        )?.long_name || "";
  
        return {
          formattedAddress,
          city,
          state,
          pincode,
        };
      } else {
        console.error("Geocoding API Error:", data.status);
        return null;
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      return null;
    }
  };

  // Function to check if location permission is blocked
  const checkGeolocationPermission = async () => {
    if (navigator.permissions) {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });
        if (permissionStatus.state === "denied") {
          setIsPermissionBlocked(true);
          setModalVisible(true); // Show modal when permission is blocked
        } else {
          setIsPermissionBlocked(false);
          fetchCurrentLocation(); // If permission is not blocked, get location
        }
      } catch (error) {
        console.error("Error checking geolocation permissions:", error);
      }
    } else {
      fetchCurrentLocation(); // Call location directly if Permissions API isn't supported
    }
  };

  const saveAddress = () => {
    if (validateForm()) {
      updateUserAddress(newAddress);
      onEditClick();
      logEvent(EVENTS.UPDATED_ADDRESS.event_name);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      city: false,
      pincode: false,
    };
    if (!newAddress?.city || newAddress.city.trim() === "") {
      newErrors.city = true;
      isValid = false;
    }
    if (!newAddress?.pincode || newAddress.pincode.trim() === "") {
      newErrors.pincode = true;
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const showBlockedModal = () => {
    return (
      <Modal
        title="Location Permission Blocked"
        open={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
      >
        <p>
          Location access is blocked in your browser. Please enable location
          permissions in your browser settings to use this feature.
        </p>
      </Modal>
    );
  };

  return (
    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
      <Card title="Address" size="default" style={{ padding: "1rem" }}>
        <Typography.Text strong>{addressText}</Typography.Text>
        <Divider />
        <Autocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
          placeholder="Search for building, society, locality..."
          onPlaceSelected={(place) => handlePlaceSelect(place)}
          options={{
            types: [],
            componentRestrictions: { country: "IN" },
          }}
          onChange={(e) => {
            setSearchAddress(e.target.value);
          }}
          value={searchAddress}
        />
        <Row gutter={[16, 16]} justify="space-between">
          {/* Map Column */}
          <Col xs={24} md={12}>
            <Button
              onClick={checkGeolocationPermission}
              loading={loading}
              type="primary"
              style={{ marginBottom: "10px", background:"#274178" }}
            >
              Current Location
            </Button>

            {(coordinates && coordinates.lat && coordinates.lng) && <AddressMap coordinates={coordinates} mapSize="100%" />}
          </Col>

          {/* Form Column */}
          <Col xs={24} md={12}>
            <Flex vertical gap="small">
              <Typography.Text>Flat No:</Typography.Text>
              <Input
                name="flat"
                value={newAddress?.flat || ""}
                style={{ marginRight: "1rem" }}
                onChange={handleInputChange}
              />

              <Typography.Text>Apartment Name:</Typography.Text>
              <Input
                name="apartment"
                value={newAddress?.apartment || ""}
                style={{ marginRight: "1rem" }}
                onChange={handleInputChange}
              />

              <Typography.Text>Street Name:</Typography.Text>
              <Input
                name="street"
                value={newAddress?.street || ""}
                style={{ marginRight: "1rem" }}
                onChange={handleInputChange}
              />

              <Typography.Text>City Name:</Typography.Text>
              <Select
                placeholder="Select city"
                value={newAddress.city || undefined} // Ensure undefined if no city is selected
                onChange={(value) =>
                  handleInputChange({ target: { name: "city", value } })
                }
                style={{ width: "100%" }}
              >
                {citysList.map((city) => (
                  <Select.Option key={city} value={city}>
                    {city}
                  </Select.Option>
                ))}
              </Select>
              {errors.city && (
                <Typography.Text type="danger">
                  City is required
                </Typography.Text>
              )}

              <Typography.Text>Pin Code:</Typography.Text>
              <Input
                name="pincode"
                value={newAddress?.pincode || ""}
                onChange={handleInputChange}
              />
              {errors.pincode && (
                <Typography.Text type="danger">
                  Pincode is required
                </Typography.Text>
              )}

              <Flex justify="flex-end" gap="small">
                <Button type="primary" danger onClick={onEditClick} style={{background:"#6a7c80"}}>
                  Cancel{" "}
                </Button>
                <Button type="primary" onClick={saveAddress} style={{background:"#274178"}}>
                  Save{" "}
                </Button>
              </Flex>
            </Flex>
          </Col>
        </Row>
      </Card>
      {isPermissionBlocked && showBlockedModal()}
    </Space>
  );
}
