import { Input } from "antd";
import { useEffect, useRef, useState } from "react";

export default function OTPInput({length, onOtpSubmit}){
    const [otp, setOtp] = useState(new Array(length).fill(""));
    const inputRefs = useRef([])
    const handleChange = (index, e)=>{
        const value = e.target.value;
        if(isNaN(value)) return
        const newOtp = [...otp];
        newOtp[index] = value.substring(value.length-1);
        setOtp(newOtp);

        // submit trigger
        const combinedOtp = newOtp.join("")
        if(combinedOtp.length === length){
            onOtpSubmit(combinedOtp);
        }
        if(value && index<length-1 && inputRefs.current[index+1]){
            inputRefs.current[index+1].focus()
        }
    }
    const handleClick = (index) =>{
        inputRefs.current[index].setSelectionRange(1,1)
        if(index>0 && !otp[index-1]){
            inputRefs.current[otp.indexOf("")].focus();
        }
    }

    const handleKeyDown = (index, e) =>{
        if(e.key==="Backspace" && !otp[index] && index>0 && inputRefs.current[index-1]){
            inputRefs.current[index-1].focus()
        }
    }

    useEffect(()=>{
        if(inputRefs.current[0]){
            inputRefs.current[0].focus()
        }
    },[])
    return(
        <div>
            {
                otp.map((value, index)=>{
                    return(
                        <Input 
                            key={index}
                            type="text"
                            ref={(input)=>(inputRefs.current[index] = input)}
                            value={value}
                            onChange={(e)=> handleChange(index, e)}
                            onKeyDown={(e)=> handleKeyDown(index, e)}
                            style={{width: "40px", height:"45px", margin:"4px 2px", textAlign:"center"}}
                            onClick={()=>handleClick(index)}
                        />
                    )
                })
            }
        </div>
    )
} 